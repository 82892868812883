import {
	EventMessage,
	EventType,
	InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LoadingButton } from "@mui/lab";
import { Divider, Skeleton, Stack } from "@mui/material";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";

import Attention from "../../components/AttentionBlock";
import { Trigger, TriggerButton } from "../../components/Link";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL } from "../../components/Typography";
import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import withProtection from "../../highOrderComponents/withProtection";
import { useContactWithPolling } from "../../hooks/useContactsWithPolling";
import { useHasCurrentContactsChange } from "../../hooks/useHasCurrentContactsChange";
import { useConfirmContacts } from "../../queryHooks";
import cs from "../../translations/cs.json";

function CheckContactInfoPage(): JSX.Element {
	const { authenticateApi } = usePrimaryAPIClient();
	const { instance, inProgress } = useMsal();
	const { data: hasCurrentContactChange, isSuccess: changesSuccess } =
		useHasCurrentContactsChange();

	const { mutate } = useConfirmContacts();
	const { data, isSuccess } = useContactWithPolling();
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		const callbackId = instance.addEventCallback((event: EventMessage) => {
			if (
				[
					EventType.LOGIN_FAILURE,
					EventType.LOGOUT_FAILURE,
					EventType.SSO_SILENT_FAILURE,
					EventType.ACQUIRE_TOKEN_FAILURE,
					EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
				].includes(event.eventType)
			) {
				if (event.error && event.error.errorMessage.indexOf("AADB2C90091") > -1)
					return;

				setErrorMessage(event.error.errorMessage);
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance]);

	const verifyContactInfo = (policyName: string) => {
		void (async () => {
			if (inProgress != InteractionStatus.None) {
				return;
			}
			try {
				const cryptographicData = await authenticateApi.encryptUserIdGet();

				const domain = process.env.GATSBY_B2C_DOMAIN as string;
				const tenantId = process.env.GATSBY_B2C_TENANT_ID as string;
				const policy = `B2C_1A_${policyName}_CHANGE_POLICY`;
				const authority = `https://${domain}/${tenantId}/${policy}`;

				// NOTE: when you see this, the variable targetUserId is probably ready to be deleted
				// it's here for backwards compatibility and seamles deployment of a new
				// AD B2C policy which uses the cryptographic cipher
				const targetUserId = instance.getActiveAccount()?.idTokenClaims?.sub;

				void instance.loginRedirect({
					scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
					authority,
					extraQueryParameters: {
						...cryptographicData,
						targetUserId: targetUserId || "",
						email: data?.userEmail || "",
						phone: data?.userPhone || "",
					},
					redirectUri: "/jsou-vase-udaje-spravne",
				});
			} catch (e) {
				verifyContactInfo(policyName);
			}
		})();
	};

	const handleConfirmButton = () => {
		if (!data?.isPhoneVerified) verifyContactInfo("PHONE");
		else if (!data?.isEmailVerified) verifyContactInfo("EMAIL");
		else
			mutate("", {
				onSuccess: () => {
					navigate("/smlouvy");
				},
			});
	};

	const allowChanges = isSuccess && changesSuccess && !hasCurrentContactChange;

	return (
		<>
			<Stack
				spacing={5}
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					backgroundColor: "white.main",
				}}
			>
				<Stack spacing={6}>
					<Stack>
						<HeadingL mb={3}>{cs.checkContactInfo.heading}</HeadingL>
						<BodyTextM>{cs.checkContactInfo.description}</BodyTextM>
						<BodyTextM>{cs.checkContactInfo.descriptionSecondLine}</BodyTextM>
					</Stack>
					{isSuccess && changesSuccess && hasCurrentContactChange && (
						<Attention severity="info">
							{cs.global.contactChangeAlreadyInProgress}
						</Attention>
					)}
					<Stack spacing={4}>
						<Stack>
							<Stack
								sx={{ flexDirection: "row", justifyContent: "space-between" }}
							>
								<BodyTextM
									sx={{
										color: "grey.dark",
									}}
									strong
								>
									{cs.global.phone}
								</BodyTextM>
								{isSuccess && changesSuccess ? (
									<Trigger
										onClick={() => verifyContactInfo("PHONE")}
										disabled={!allowChanges}
									>
										{cs.global.change}
									</Trigger>
								) : (
									<Skeleton height={32} width={64} />
								)}
							</Stack>
							<BodyTextM>
								{isSuccess ? (
									<BodyTextM>{data.userPhone}</BodyTextM>
								) : (
									<Skeleton variant="rounded" height={32} />
								)}
							</BodyTextM>
							{isSuccess && data.isPhoneVerified && (
								<Attention severity="success">
									{cs.postLogin.phoneVerified}
								</Attention>
							)}
						</Stack>
						<Divider />
						<Stack>
							<Stack
								sx={{ flexDirection: "row", justifyContent: "space-between" }}
							>
								<BodyTextM
									sx={{
										color: "grey.dark",
									}}
									strong
								>
									{cs.global.email}
								</BodyTextM>
								{isSuccess && changesSuccess ? (
									<Trigger
										onClick={() => verifyContactInfo("EMAIL")}
										disabled={!allowChanges}
									>
										{cs.global.change}
									</Trigger>
								) : (
									<Skeleton width={64} height={32} />
								)}
							</Stack>
							{isSuccess ? (
								<BodyTextM>{data.userEmail}</BodyTextM>
							) : (
								<Skeleton variant="rounded" height={32} />
							)}
							{isSuccess && data.isEmailVerified && (
								<Attention severity="success">
									{cs.postLogin.emailVerified}
								</Attention>
							)}
						</Stack>
					</Stack>
					<Stack direction={"row"} gap={4}>
						<TriggerButton
							variant="contained"
							onClick={() => {
								void navigate("/smlouvy");
							}}
						>
							{cs.global.home}
						</TriggerButton>
						<LoadingButton
							disableElevation
							variant="contained"
							sx={{ width: "100%" }}
							loading={!isSuccess || !changesSuccess}
							disabled={!allowChanges}
							onClick={handleConfirmButton}
						>
							{cs.global.continue}
						</LoadingButton>
					</Stack>
					{errorMessage && (
						<Attention severity="error">{errorMessage}</Attention>
					)}
				</Stack>
			</Stack>
		</>
	);
}

export default withProtection(CheckContactInfoPage, "jsou-vase-udaje-spravne");

export const Head = (): JSX.Element => (
	<SEO title={cs.checkContactInfo.heading} />
);
