import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";

import { PostLoginResponse } from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { checkStopCondition } from "../functions/checkStopCondition";

interface MsalData {
	email?: string;
	phone?: string;
}

type ContactsWithPollingResult =
	| {
			isSuccess: false;
			data: undefined;
	  }
	| {
			isSuccess: true;
			data: PostLoginResponse;
	  };

export const useContactWithPolling = (): ContactsWithPollingResult => {
	const { postLoginApi } = usePrimaryAPIClient();
	const { instance } = useMsal();
	const [data, setData] = useState<PostLoginResponse>();
	const pollUserData = useCallback(
		async (msal: MsalData, retries: number): Promise<PostLoginResponse> => {
			const data = await postLoginApi.getPostLoginInfoGet();
			if (
				[
					{
						msalData: msal.email,
						fetchedData: data.userEmail,
						fetchedVerification: data.isEmailVerified,
					},
					{
						msalData: msal.phone,
						fetchedData: data.userPhone,
						fetchedVerification: data.isPhoneVerified,
					},
				].every(checkStopCondition) ||
				retries > 20
			) {
				return data;
			}
			await new Promise((resolve) => setTimeout(resolve, 2000));
			return await pollUserData(msal, retries + 1);
		},
		[postLoginApi]
	);

	useEffect(() => {
		void (async () => {
			const accounts = instance.getAllAccounts();
			const data = await pollUserData(
				{
					email: accounts.find(
						(account) => account.idTokenClaims?.email !== undefined
					)?.idTokenClaims?.email as string | undefined,
					phone: accounts.find(
						(account) =>
							account.idTokenClaims?.extension_PhoneNumber !== undefined
					)?.idTokenClaims?.extension_PhoneNumber as string | undefined,
				},
				0
			);
			setData(data);
		})();
	}, [instance, pollUserData]);

	if (data !== undefined) {
		return { isSuccess: true, data };
	}

	return { isSuccess: false, data };
};
